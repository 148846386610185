import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
import State from '../../components/State';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Use the pagination component to create a connected set of links that lead to related pages (for example, previous, next, or page numbers).`}</p>
    <h2>{`Basic example`}</h2>
    <p>{`The pagination component only requires two properties to render: `}<inlineCode parentName="p">{`pageCount`}</inlineCode>{`, which is the total number of pages, and `}<inlineCode parentName="p">{`currentPage`}</inlineCode>{`, which is the currently selected page number (which should be managed by the consuming application).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Pagination
  pageCount={15}
  currentPage={2}
  onPageChange={e => e.preventDefault()}
/>
`}</code></pre>
    <p>{`However, to handle state changes when the user clicks a page, you also need to pass `}<inlineCode parentName="p">{`onPageChange`}</inlineCode>{`, which is a function that takes a click event and page number as an argument:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`type PageChangeCallback = (evt: React.MouseEvent, page: number) => void
`}</code></pre>
    <p>{`By default, clicking a link in the pagination component will cause the browser to navigate to the URL specified by the page. To cancel navigation and handle state management on your own, you should call `}<inlineCode parentName="p">{`preventDefault`}</inlineCode>{` on the event, as in this example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<State default={1}>
  {([page, setPage]) => {
    const totalPages = 15
    const onPageChange = (evt, page) => {
      evt.preventDefault()
      setPage(page)
    }

    return (
      <BorderBox p={2}>
        <Box>Current page: {page} / {totalPages}</Box>
        <Pagination
          pageCount={totalPages}
          currentPage={page}
          onPageChange={onPageChange}
        />
      </BorderBox>
    )
  }}
</State>
`}</code></pre>
    <h2>{`Customizing link URLs`}</h2>
    <p>{`To customize the URL generated for each link, you can pass a function to the `}<inlineCode parentName="p">{`hrefBuilder`}</inlineCode>{` property. The function should take a page number as an argument and return a URL to use for the link.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`type HrefBuilder = (page: number) => string
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<State default={'(nothing clicked yet)'}>
  {([lastUrl, setLastUrl]) => {
    const onPageChange = (evt, page) => {
      evt.preventDefault()
      setLastUrl(evt.target.href)
    }
    const hrefBuilder = (page) => {
      return \`https://example.com/pages/\${page}\`
    }

    return (
      <BorderBox p={2}>
        <Box>The last URL clicked was: {lastUrl}</Box>
        <Pagination
          pageCount={15}
          currentPage={2}
          onPageChange={onPageChange}
          hrefBuilder={hrefBuilder}
        />
      </BorderBox>
    )
  }}
</State>
`}</code></pre>
    <h2>{`Customizing which pages are shown`}</h2>
    <p>{`Two props control how many links are displayed in the pagination container at any given time. `}<inlineCode parentName="p">{`marginPageCount`}</inlineCode>{` controls how many pages are guaranteed to be displayed on the left and right of the component; `}<inlineCode parentName="p">{`surroundingPageCount`}</inlineCode>{` controls how many pages will be displayed to the left and right of the current page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Pagination
  pageCount={20}
  currentPage={10}
  marginPageCount={1}
  surroundingPageCount={2}
  onPageChange={e => e.preventDefault()}
/>
`}</code></pre>
    <p>{`The algorithm tries to minimize the amount the component shrinks and grows as the user changes pages; for this reason, if any of the pages in the margin (controlled via `}<inlineCode parentName="p">{`marginPageCount`}</inlineCode>{`) intersect with pages in the center (controlled by `}<inlineCode parentName="p">{`surroundingPageCount`}</inlineCode>{`), the center section will be shifted away from the margin. Consider the following examples, where pages one through six are shown when any of the first four pages are selected. Only when the fifth page is selected and there is a gap between the margin pages and the center pages does a break element appear.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Box>
  {[1, 2, 3, 4, 5].map(page => (
    <Pagination
      pageCount={20}
      currentPage={page}
      marginPageCount={1}
      surroundingPageCount={2}
      onPageChange={e => e.preventDefault()}
    />
  ))}
</Box>
`}</code></pre>
    <h3>{`Previous/next pagination`}</h3>
    <p>{`To hide all the page numbers and create a simple pagination container with just the Previous and Next buttons, set `}<inlineCode parentName="p">{`showPages`}</inlineCode>{` to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<State default={1}>
  {([page, setPage]) => {
    const totalPages = 10
    const onPageChange = (evt, page) => {
      evt.preventDefault()
      setPage(page)
    }

    return (
      <BorderBox p={2}>
        <Box>Current page: {page} / {totalPages}</Box>
        <Pagination
          pageCount={totalPages}
          currentPage={page}
          onPageChange={onPageChange}
          showPages={false}
        />
      </BorderBox>
    )
  }}
</State>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Pagination components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`currentPage`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Required.`}</strong>{` The currently selected page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`hrefBuilder`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`#\${page}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A function to generate links based on page number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`marginPageCount`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`How many pages to always show at the left and right of the component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onPageChange`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`no-op`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Called with event and page number when a page is clicked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`pageCount`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Required.`}</strong>{` The total number of pages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`showPages`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether or not to show the individual page links.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`surroundingPageCount`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`How many pages to display on each side of the currently selected page.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Theming`}</h2>
    <p>{`The following snippet shows the properties in the theme that control the styling of the pagination component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  // ... rest of theme ...
  pagination: {
    borderRadius,
    spaceBetween,
    colors: {
      normal: {
        fg
      },
      disabled: {
        fg,
        border
      },
      hover: {
        border
      },
      selected: {
        fg,
        bg,
        border
      },
      active: {
        border
      },
      nextPrevious: {
        fg
      }
    }
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      